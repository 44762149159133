import "./CopyButton.scss";

import React, { useState } from "react";

import Button from "../Button/Button";
import Copy from "./copy.svg?react";

interface CopyButtonProps {
  message: string;
  className?: string;
  label?: string;
  width?: number;
  height?: number;
  reverse?: boolean;
  onClick?: () => void;
}

const CopyButton: React.FC<CopyButtonProps> = ({
  message,
  className,
  label,
  width = 24,
  height = 24,
  reverse = false,
  onClick,
}) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    if (onClick) await onClick();
    navigator.clipboard.writeText(message);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1100); // 1 seconds
  };

  return (
    <>
      <div
        className={`${className ?? ""} toolsBarCopyIcon`}
        aria-label={`Copy ${message} to clipboard`}
        role="button"
      >
        <Button
          onClick={copyToClipboard}
          className={`toolsBarCopyButton ${copied ? "active" : ""} ${
            reverse ? "reverse" : ""
          }`}
          icon={<Copy />}
          label={label}
          style={label ? "transparent" : "square"}
          height={height}
          width={label ? "max-content" : width}
          padding="0"
        />
        {copied && (
          <div className={`copiedMessage ${copied ? "active" : ""}`}>
            Copied
          </div>
        )}
      </div>
    </>
  );
};

export default CopyButton;
